const cartList = [
    {
        id: 1,
        name: "Shrimp Alfredo",
        price: 16,
        image: "img/mains/shrimpalfredo.jpg",
        course: "main",
        special: true,
        qty: 1

    },
    {
        id: 2,
        name: "Lobster Mac & Cheese",
        price: 22,
        image: "img/mains/lobster-mac.jpg",
        course: "main",
        special: true,
        qty: 1

    },
    
];

export default cartList;